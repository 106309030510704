<template>
  <div id="root">
    <div class="title">卡密管理</div>

    <div class="box">
      <el-input placeholder="账号" v-model="username"></el-input>
      <el-input placeholder="密码" v-model="password" type="password" style="margin-top: 20px;"></el-input>
      <el-button type="primary" style="height:50px;width: 100%;margin-top: 20px;" @click="login">登录</el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CardAdmin",
  data(){
    return{
      username:"",
      password:""
    }
  },
  methods:{
    async login(){
      try {
        const res = await axios.post(process.env.VUE_APP_BASE_API + "cards/adminlogin", {
          username: this.username,
          password: this.password
        });
        const data = res.data;
        if (data.code != 200) {
          this.$message({
            type: "error",
            message: data.message
          })
          return;
        }
        this.$router.replace({
          name:"CardManager"
        })
      } catch (e) {
        this.$alert(e)
      }
    }
  }
}
</script>

<style scoped>
#root{
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}
.title{
  padding: 20px;
  font-weight: 900;
  font-size: 25px;
  text-align: center;
}
.box{
  width: 60%;
}
</style>